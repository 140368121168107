<template>
  <card>
    <div class="custom-container p-3">
      <h4 class="d-flex flex-column flex-lg-row align-items-center justify-content-between">
        <b-link :to="{ path: '/helex/pesv' }" replace>
          <i class="fas fa-arrow-left"></i>
          Regresar
        </b-link>

        <vs-button id="startButton" v-b-tooltip.hover title="Iniciar tutorial" circle icon danger relief
          @click="startTour">
          <i class="far fa-circle-play mr-1"></i>
          Tutorial Rápido
        </vs-button>
      </h4>
      <!-- tour guide -->
      <tour-guide ref="tourGuide" />
      <div class="mt-3 d-flex flex-column" style="gap: 0.5rem">
        <h3>
          <b>
            {{
              this.$route.params.idEncuesta == 1
                ? "FORMULARIO SISI/PESV"
                : "Subindicadores"
            }}</b>
        </h3>
        <template v-if="report && this.$route.params.idEncuesta == 1">
          <h4 class="text-white-50">
            <b> Clasificación: {{ report.clasificaion }}</b>
          </h4>
          <p class="h4">
            {{ report.descripcionClasificacion }}
          </p>
          <h4 class="text-white-50">
            <b> Total de conductores: </b>
            <span>{{ report.totalConductores }}</span>
          </h4>
          <h4 class="text-white-50">
            <b> Total de vehículos: </b>
            <span>{{ report.totalVehiculos }}</span>
          </h4>
          <h4 class="text-white-50">
            <b> Obligado: </b>
            <span>Si</span>
          </h4>
        </template>

        <template v-else-if="report && this.$route.params.idEncuesta == 2">
          <p>{{ report.mensaje }}</p>
          <h4 class="text-white-50">
            <b> Vigencia: </b>
            <span>{{ report.vigencia }}</span>
          </h4>
          <h4 class="text-white-50">
            <b> Obligado: </b>
            <span>Si</span>
          </h4>
        </template>

        <template v-else>
          <p>No se encontraron los datos</p>
        </template>
      </div>
      <hr />

      <!-- formulario fase 1 -->
      <PesvFase1 v-if="this.$route.params.idEncuesta == 1" />

      <!-- formulario fase 2 -->
      <PesvFase2 v-else-if="this.$route.params.idEncuesta == 2" />
    </div>
  </card>
</template>

<script>
const Swal = require("sweetalert2");
import PesvFase1 from "./PesvFase1.vue";
import PesvFase2 from "./PesvFase2.vue";
// tour guide
import TourGuide from "@/components/helexium/TourGuide.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    PesvFase1,
    PesvFase2,
    TourGuide,
  },
  data() {
    return {
      report: {},
      tourStepsFase1: [
        {
          title: "Sucursal",
          description:
            "Primero selecciona una sucursal, se cargaran los datos guardados si la evaluación aún está en proceso.",
          elementId: "sucursal",
        },
        {
          title: "Nueva evaluación",
          description:
            "Sí la sucursal seleccionada no tiene proceso pendiente, podrás crear una evaluación nueva.",
          elementId: "newEvaluacion",
        },
        {
          title: "Pasos",
          description:
            "La información a diligenciar se encuentran en los pasos 1 al 4.",
          elementId: "seleccion",
        },
        {
          title: "Completa el formulario",
          description:
            "Rellena los campos y carga los documentos requeridos, para cada uno de los pasos seleccionados.",
          elementId: "formFase",
        },
        {
          title: "Archivos",
          description:
            "Aquí podrás ver el listado de archivos cargados en las diferentes fases.",
          elementId: "files",
        },
        {
          title: "Guardar Evaluación",
          description:
            "Si por algún motivo no puedes continuar con el proceso, puedes guardar el progreso en cualquier momento.",
          elementId: "save",
        },
        {
          title: "Resultados",
          description:
            "Finalmente puedes observar los resultados obtenidos. Buena suerte!",
          elementId: "results",
        },
      ],
      tourStepsFase2: [
        {
          title: "Periodo",
          description:
            "Selecciona el periodo a diligenciar, se cargaran los datos guardados.",
          elementId: "periodo",
        },
        {
          title: "Completa el formulario",
          description:
            "Rellena los campos y carga los documentos requeridos, para cada uno de los formularios. Algunos datos sólo se requieren trimestralmente.",
          elementId: "formFase2",
        },
        {
          title: "Guardar datos",
          description:
            "Si por algún motivo no puedes continuar con el proceso, puedes guardar el progreso en cualquier momento.",
          elementId: "saveform2",
        },
      ],
    };
  },
  mounted() {
    this.getReport();
  },
  computed: {
    ...mapState("pesv", ["TOKEN_SISI"]),
  },
  methods: {
    ...mapActions("pesv", ["useRequestSisi"]),
    startTour() {
      this.$refs.tourGuide.startTour(
        this.$route.params.idEncuesta == 1
          ? this.tourStepsFase1
          : this.tourStepsFase2
      );
    },
    async getReport() {
      this.$isLoading(true);

      const { idEncuesta } = this.$route.params;
      const { vigilado, reporte } = this.$route.query;

      const path =
        idEncuesta == 1
          ? "encuestas/obtener"
          : "inidicador/obtener-formularios";

      const params = {
        idVigilado: vigilado,
        idReporte: reporte,
        token: this.TOKEN_SISI,
      };

      if (idEncuesta == 2) {
        params.idMes = 1;
        params.historico = false;
      } else {
        params.idEncuesta = idEncuesta;
      }

      const reportResponde = await this.useRequestSisi({
        method: "GET",
        path,
        params,
      });

      if (!reportResponde.ok) {
        this.$isLoading(false);
        Swal.fire(
          "Error!",
          "No se pudo obtener el reporte con Sisi",
          "error"
        )
        return
      }

      this.report = reportResponde.data;

      this.$isLoading(false);
    },
  },
};
</script>

<style scoped></style>
