<template>
  <div class="p-3">
    <div v-for="(item, index) in filteredModel" :key="index" :class="`row p-3 ${index !== filteredModel.length - 1 && 'border-bottom'
      }`" style="gap: 0.5rem">
      <div class="col-12 col-md-1">
        <b>
          {{ item.id }}
        </b>
      </div>
      <div class="col-12 col-md-3" style="line-height: 1.5">
        {{ item.descripcion }}
      </div>
      <div class="col-12 col-md-2">
        {{ item.tipoEvidencia }}
      </div>
      <div class="col-12 col-md-3">
        <b-form-input v-if="item.tipoEvidencia.includes('Numéricos')" type="number" v-model="item.value"></b-form-input>
        <div v-else class="d-flex flex-column" style="gap: 0.5rem">
          <span>
            <b> Máximo {{ item.maxSize }}</b>
          </span>
          <DropFile @handleFilesSisi2="handleFilesSisi2"
            :urlDir="`users/pesvFase2/evidencias/${usuario.empresa.id}/${currencyModel}/${vigencia}/${periodo}/${item.id}`"
            :pathGet="`PesvFase2/GetPesvFase2ListaArchivosId/${usuario.empresa.id}/${currencyModel}/${vigencia}/${periodo}/${item.id}`"
            pathPost="PesvFase2/CreatePesvFase2Archivo" :injectData="{
              EmpresaId: usuario.empresa.id,
              Periodo: periodo,
              NumeroReporte: numeroReporte,
              Vigencia: vigencia,
              Form: currencyModel,
              NumeroEvidencia: item.id,
              FechaArchivo: $moment
                .tz(new Date(), 'America/Costa_Rica')
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            }" :fileTypes="[
              'application/pdf',
              'application/docx',
              'image/jpeg',
              'image/png',
              'image/gif',
              'application/msword',
              'application/vnd.ms-excel',
              'application/vnd.ms-powerpoint',
              'text/plain',
              'image/*',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.ms-powerpoint',
              'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
            ]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropFile from "@/components/helexium/DropFileV2.vue";
export default {
  components: {
    DropFile,
  },
  props: {
    periodo: Number,
    usuario: Object,
    trimestralSelected: Boolean,
    currencyModel: String,
    vigencia: String,
    numeroReporte: Number
  },
  data() {
    return {

    };
  },
  computed: {
    modelStore() {
      return this.$store.state.formsPesvFase2[this.currencyModel]["evidencias"];
    },
    filteredModel() {
      if (this.trimestralSelected) {
        return this.modelStore;
      } else {
        return this.modelStore.filter((item) => !item.trimestral);
      }
    },
  },
  methods: {
    handleFilesSisi2(data) {
      this.$emit("handleFilesSisi2", data);
    },
  },
};
</script>

<style scoped></style>
