var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3"},_vm._l((_vm.filteredModel),function(item,index){return _c('div',{key:index,class:`row p-3 ${index !== _vm.filteredModel.length - 1 && 'border-bottom'
    }`,staticStyle:{"gap":"0.5rem"}},[_c('div',{staticClass:"col-12 col-md-1"},[_c('b',[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('div',{staticClass:"col-12 col-md-3",staticStyle:{"line-height":"1.5"}},[_vm._v(" "+_vm._s(item.descripcion)+" ")]),_c('div',{staticClass:"col-12 col-md-2"},[_vm._v(" "+_vm._s(item.tipoEvidencia)+" ")]),_c('div',{staticClass:"col-12 col-md-3"},[(item.tipoEvidencia.includes('Numéricos'))?_c('b-form-input',{attrs:{"type":"number"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"0.5rem"}},[_c('span',[_c('b',[_vm._v(" Máximo "+_vm._s(item.maxSize))])]),_c('DropFile',{attrs:{"urlDir":`users/pesvFase2/evidencias/${_vm.usuario.empresa.id}/${_vm.currencyModel}/${_vm.vigencia}/${_vm.periodo}/${item.id}`,"pathGet":`PesvFase2/GetPesvFase2ListaArchivosId/${_vm.usuario.empresa.id}/${_vm.currencyModel}/${_vm.vigencia}/${_vm.periodo}/${item.id}`,"pathPost":"PesvFase2/CreatePesvFase2Archivo","injectData":{
            EmpresaId: _vm.usuario.empresa.id,
            Periodo: _vm.periodo,
            NumeroReporte: _vm.numeroReporte,
            Vigencia: _vm.vigencia,
            Form: _vm.currencyModel,
            NumeroEvidencia: item.id,
            FechaArchivo: _vm.$moment
              .tz(new Date(), 'America/Costa_Rica')
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          },"fileTypes":[
            'application/pdf',
            'application/docx',
            'image/jpeg',
            'image/png',
            'image/gif',
            'application/msword',
            'application/vnd.ms-excel',
            'application/vnd.ms-powerpoint',
            'text/plain',
            'image/*',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
          ]},on:{"handleFilesSisi2":_vm.handleFilesSisi2}})],1)],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }