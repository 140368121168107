<template>
  <card>
    <div class="text-center p-4">
      <PowerBiIframe
        class="container-iframe"
        title="Reporte PESV"
        :user="userLoggedIn"
        :reportData="aboutSeccionResultados"
      ></PowerBiIframe>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import PowerBiIframe from "@/components/helexium/PowerBiIframe.vue";
export default {
  components: {
    PowerBiIframe, // Asegúrate de importar o definir correctamente el componente PowerBiIframe aquí
  },
  data() {
    return {
      aboutSeccionResultados: {},
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.getAboutSeccionResultados();
    this.$isLoading(false);
  },
  computed: {
    ...mapGetters(["userLoggedIn"]),
  },
  methods: {
    getAboutSeccionResultados() {
      try {
        const empresaId = this.userLoggedIn.empresa.id;
        this.$store.getters
          .fetchGet({ path: `IFrame/empresa/${empresaId}/seccion/PESV/last` })
          .then((repsonse) => {
            if (repsonse.ok) {
              return repsonse.json();
            }
          })
          .then((result) => {
            this.aboutSeccionResultados = result;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style scoped>
.container-iframe {
  width: 100%;
  min-height: 70vh;
  position: relative;
}
</style>
