<template>
  <div class="custom-container">
    <!-- listado de buttons para cambiar de fases -->
    <EvaluationButtons :items="items" />

    <!-- formulario fase1 pesv -->
    <form @submit.prevent="saveData()">
      <EvaluationStepCard v-for="(area, index) in filteredItems" :key="index" :stepItems="area.Areas" :item="item"
        @handleFilesSisi="handleFilesSisi" @sendReport="sendReport" />
    </form>

    <!-- listado de archivos cargados en las fases -->
    <ListFiles v-if="activePasoFase1 == 5" :item="item" />

    <!-- seccion de resultados -->
    <AboutSeccionResults v-if="activePasoFase1 == 6" />

    <!-- StepNavigationWithScroll -->
    <StepNavigationWithScroll :items="items" @saveData="saveData" />
  </div>
</template>
<script>
// components
import EvaluationStepCard from "./pesv_fase1_components/EvaluationStepCard.vue";
import ListFiles from "./pesv_fase1_components/ListFiles.vue";
import AboutSeccionResults from "./pesv_fase1_components/AboutSeccionResults.vue";
import EvaluationButtons from "./pesv_fase1_components/EvaluationButtons.vue";
import StepNavigationWithScroll from "./pesv_fase1_components/StepNavigationWithScroll.vue";
import { mapActions, mapState } from "vuex";

// alert
const Swal = require("sweetalert2");

export default {
  components: {
    EvaluationStepCard,
    ListFiles,
    AboutSeccionResults,
    EvaluationButtons,
    StepNavigationWithScroll,
  },
  name: "pesv",
  data() {
    return {
      userLogged: null,
      isSisiAuth: false,
      items: [{ Areas: [] }, { Areas: [] }, { Areas: [] }, { Areas: [] }],
      item: {
        EmpresaId: 0,
        NumeroReporte: parseInt(this.$route.query.reporte),
        FechaIngreso: this.$moment
          .tz(new Date(), "America/Costa_Rica")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        FechaEvaluacion: this.$moment
          .tz(new Date(), "America/Costa_Rica")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        Estado: "",
      },
    };
  },
  created() {
    this.userLogged = JSON.parse(localStorage.getItem("setPersonaAct"));
    if (this.userLogged.rol.nivel == 6) {
      this.$router.push("/helex/inspeccionVehiculo");
    }
  },
  mounted() {
    this.initializeData();
  },
  computed: {
    ...mapState("pesv", ["activePasoFase1", "TOKEN_SISI", "credential"]),
    filteredItems() {
      return this.items
        .filter((_, index) => index + 1 === this.activePasoFase1) // Filtra los ítems para mostrar solo los que corresponden al paso actual.
        .map((item) => ({
          // Itera sobre cada área de cada ítem.
          ...item,
          Areas: item.Areas.map((area) => {
            // Agregar collapsed de forma reactiva si no existe
            if (typeof area.collapsed === "undefined") {
              this.$set(area, "collapsed", true);
            }
            return area;
          }),
        }));
    },
  },
  methods: {
    ...mapActions("pesv", ["useRequestSisi"]),
    async initializeData() {

      // Verificar si existe autenticación de Sisi
      const isSisiAuth = localStorage.getItem("sisiAuth");

      this.isSisiAuth = isSisiAuth ? true : false;

      if (!isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        )
      }

      const { empresa } = this.userLogged
      const { reporte } = this.$route.query

      this.item.EmpresaId = empresa.id;
      this.items = await this.getData();
      await this.getEvaluacionByEmpresaAndReport(empresa.id, parseInt(reporte));
    },
    async handleFilesSisi(filesData) {

      if (!this.isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        );
        return;
      }

      // Subir archivos a Sisi
      const formData = new FormData();
      formData.append("archivo", filesData.file);
      formData.append("idPregunta", filesData.pasoId + 2);
      formData.append("idVigilado", this.credential.usuario);
      formData.append("token", this.TOKEN_SISI);

      const fileUploadResponse = await this.useRequestSisi({
        method: "POST",
        path: "archivos",
        body: formData,
        isFormData: true
      });

      if (!fileUploadResponse.ok) {
        Swal.fire(
          "Error!",
          "No se pudo subir el archivo al servidor de Sisi",
          "error"
        );
        return;
      }

      const newItem = { data: fileUploadResponse.data, pasoId: filesData.pasoId };

      let filesSent = JSON.parse(localStorage.getItem("filesSentFase1")) || [];

      const index = filesSent.findIndex(item => item.pasoId === newItem.pasoId)

      if (index !== -1) {
        filesSent[index] = newItem;
      } else {
        filesSent.push(newItem);
      }

      // console.log("filesSent", filesSent);

      // Guardar la lista actualizada en localStorage
      localStorage.setItem("filesSentFase1", JSON.stringify(filesSent));
    },
    assignDocuments(pasoId) {

      const filesSent = JSON.parse(localStorage.getItem("filesSentFase1")) || [];

      const assignDoc = filesSent.find(file => file.pasoId === pasoId);

      return assignDoc ? assignDoc.data : {};
    },

    async crearEvaluacion() {
      this.item.Estado = "En progreso";
      let res = await this.$store.dispatch("hl_post", {
        path: "PesvPasosEvaluacion/Create/",
        data: this.item,
      });
      // console.log(res);
      this.item.id = res;
    },
    async getEvaluacionByEmpresaAndReport(empresaId, numeroReporte) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `PesvPasosEvaluacion/${empresaId}/${numeroReporte}`,
        });
        // console.log("get evaluación by empresa... ", res);
        if (res.length > 0) {
          // console.log("1");
          this.item = res[0];
          this.item.FechaEvaluacion = this.$moment
            .tz(this.item.FechaEvaluacion, "America/Costa_Rica")
            .format("YYYY-MM-DD");
          //consultando pasos
          let resPasos = await this.$store.dispatch("hl_get", {
            path: "PesvPasosEvaluacion/GetPasosEvaluacion/" + this.item.id,
          });
          // console.log(" pasos *****************************", resPasos);
          //recorrer areas en this.items asignando valor de acuerdo a respasos
          let items = await this.getData();
          for await (let itData of items[0].Areas) {
            for await (let itemPaso of resPasos) {
              if (itData.Id == itemPaso.pasoId) {
                // console.log(itemPaso);
                itData.value = itemPaso.calificacionId;
                itData.motivo =
                  itemPaso.motivoId == null ? undefined : itemPaso.motivoId;
                itData.PesvPasoId = itemPaso.id;
              }
            }
          }
          for await (let itData of items[1].Areas) {
            for await (let itemPaso of resPasos) {
              if (itData.Id == itemPaso.pasoId) {
                // console.log(itemPaso);
                itData.value = itemPaso.calificacionId;
                itData.motivo =
                  itemPaso.motivoId == null ? undefined : itemPaso.motivoId;
                itData.PesvPasoId = itemPaso.id;
              }
            }
          }
          for await (let itData of items[2].Areas) {
            for await (let itemPaso of resPasos) {
              if (itData.Id == itemPaso.pasoId) {
                // console.log(itemPaso);
                itData.value = itemPaso.calificacionId;
                itData.motivo =
                  itemPaso.motivoId == null ? undefined : itemPaso.motivoId;
                itData.PesvPasoId = itemPaso.id;
              }
            }
          }
          for await (let itData of items[3].Areas) {
            for await (let itemPaso of resPasos) {
              if (itData.Id == itemPaso.pasoId) {
                // console.log(itemPaso);
                itData.value = itemPaso.calificacionId;
                itData.motivo =
                  itemPaso.motivoId == null ? undefined : itemPaso.motivoId;
                itData.PesvPasoId = itemPaso.id;
              }
            }
          }
          this.items = items;
          // console.log(" items ", this.items);
        } else {
          this.items = await this.getData();
          // console.log("2");
          this.item = {
            EmpresaId: this.userLogged.empresa.id,
            NumeroReporte: parseInt(this.$route.query.reporte),
            FechaIngreso: this.$moment
              .tz(new Date(), "America/Costa_Rica")
              .format("YYYY-MM-DD"),
            FechaEvaluacion: this.$moment
              .tz(new Date(), "America/Costa_Rica")
              .format("YYYY-MM-DD"),
            Estado: "",
          };

          this.crearEvaluacion();
          this.listaArchivos = [];
        }
      } catch (error) {
        this.item = {
          EmpresaId: this.userLogged.empresa.id,
          NumeroReporte: parseInt(this.$route.query.reporte),
          FechaIngreso: this.$moment
            .tz(new Date(), "America/Costa_Rica")
            .format("YYYY-MM-DD"),
          FechaEvaluacion: this.$moment
            .tz(new Date(), "America/Costa_Rica")
            .format("YYYY-MM-DD"),
          Estado: "",
        };
        console.log("err", error);
      }
    },
    async generarPasosEvaluacion(PesvEvaluacionId) {
      let data = [];
      this.item.Estado = "Finalizado";
      for await (let it of this.items[0].Areas) {
        // console.log("pasoid / valor", it.Id, " ", it.value);
        if (it.value != undefined) {
          let itemData = {
            Id: it.PesvPasoId,
            PesvEvaluacionId: PesvEvaluacionId,
            PasoId: it.Id,
            CalificacionId: it.value,
            MotivoId: it.motivo == undefined ? null : it.motivo,
          };
          data.push(itemData);
        } else {
          this.item.Estado = "En progreso";
        }
      }
      for await (let it of this.items[1].Areas) {
        // console.log("pasoid / valor", it.Id, " ", it.value, it.motivo);
        if (it.value != undefined) {
          let itemData = {
            Id: it.PesvPasoId,
            PesvEvaluacionId: PesvEvaluacionId,
            PasoId: it.Id,
            CalificacionId: it.value,
            MotivoId: it.motivo,
          };
          data.push(itemData);
        } else {
          this.item.Estado = "En progreso";
        }
      }
      for await (let it of this.items[2].Areas) {
        // console.log("pasoid / valor", it.Id, " ", it.value, it.motivo);
        if (it.value != undefined) {
          let itemData = {
            Id: it.PesvPasoId,
            PesvEvaluacionId: PesvEvaluacionId,
            PasoId: it.Id,
            CalificacionId: it.value,
            MotivoId: it.motivo,
          };
          data.push(itemData);
        } else {
          this.item.Estado = "En progreso";
        }
      }
      for await (let it of this.items[3].Areas) {
        // console.log("pasoid / valor", it.Id, " ", it.value, it.motivo);
        if (it.value != undefined) {
          let itemData = {
            Id: it.PesvPasoId,
            PesvEvaluacionId: PesvEvaluacionId,
            PasoId: it.Id,
            CalificacionId: it.value,
            MotivoId: it.motivo,
          };
          data.push(itemData);
        } else {
          this.item.Estado = "En progreso";
        }
      }
      // console.log("data", data);
      return data;
    },
    clonarYReasignarIds(array, idsParaClonar) {

      let arrayActualizado = [];
      idsParaClonar.forEach(({ id, cantidad }) => {
        let objetoOriginal = array.find(item => item.preguntaId === id);
        // console.log(objetoOriginal, id, cantidad);
        if (objetoOriginal) {
          for (let i = 0; i < cantidad; i++) {
            let objetoClonado = { ...objetoOriginal };
            arrayActualizado.push(objetoClonado);
          }
        }
      });

      const newArr = [...arrayActualizado, ...array].sort((a, b) => a.preguntaId - b.preguntaId);

      let finalArr = [];
      newArr.forEach(item => {
        idsParaClonar.forEach(({ id, ids }) => {
          if (item.preguntaId === id) {
            ids.forEach((idItem) => {
              let newItem = { ...item, preguntaId: idItem };
              let indexOf = finalArr.findIndex(item => item.preguntaId === idItem);
              if (indexOf > -1) {
                finalArr[indexOf] = newItem;
              } else {
                finalArr.push(newItem);
              }
            })
          }
        })
      });

      return finalArr;
    },
    async sendReport() {
      if (!this.isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        );
        return;
      }
      this.$isLoading(true);
      const body = {
        idEncuesta: "1",
        idReporte: this.$route.query.reporte,
        idVigilado: this.credential.usuario,
        confirmar: false,
        token: this.TOKEN_SISI,
      }

      // enviar reporte a Sisi
      const sendRespuesta = await this.useRequestSisi({
        method: "POST",
        path: "encuestas/enviar-reporte/",
        body
      });

      if (!sendRespuesta.ok) {
        Swal.fire("Notificación!", sendRespuesta.data.mensaje, "warning");
        this.$isLoading(false);
        return
      }

      Swal.fire(
        "Listo!",
        `Se ha enviado el formulario correctamente.`,
        "success"
      );

      this.$isLoading(false);
    },

    processRespuestas(data) {

      const respuestas = data.map((item) => {
        return {
          preguntaId: item.PasoId + 2,
          valor: `${item.CalificacionId == 1 ? "S" : "N"}`,
          ...this.assignDocuments(item.PasoId),
          observacion: `${item.MotivoId == null ? "" : item.MotivoId}`,
        }
      });

      // IDs a clonar y la cantidad de veces a clonar cada uno
      const idsParaClonar = [
        { id: 3, cantidad: 0, ids: [3] },
        { id: 4, cantidad: 0, ids: [4] },
        { id: 5, cantidad: 0, ids: [5] },
        { id: 6, cantidad: 0, ids: [6] },
        { id: 7, cantidad: 1, ids: [7, 8] },
        { id: 8, cantidad: 1, ids: [9, 10] },
        { id: 9, cantidad: 1, ids: [11, 12] },
        { id: 10, cantidad: 5, ids: [13, 14, 15, 16, 17, 18] },
        { id: 11, cantidad: 0, ids: [19] },
        { id: 12, cantidad: 1, ids: [20, 21] },
        { id: 13, cantidad: 3, ids: [22, 23, 24, 25] },
        { id: 14, cantidad: 0, ids: [26] },
        { id: 15, cantidad: 0, ids: [27] },
        { id: 16, cantidad: 4, ids: [28, 29, 30, 31, 32] },
        { id: 17, cantidad: 0, ids: [33] },
        { id: 18, cantidad: 2, ids: [34, 35, 36] },
        { id: 19, cantidad: 1, ids: [37, 38] },
        { id: 20, cantidad: 4, ids: [39, 40, 41, 42, 43] },
        { id: 21, cantidad: 0, ids: [44] },
        { id: 22, cantidad: 0, ids: [45] },
        { id: 23, cantidad: 0, ids: [46] },
        { id: 24, cantidad: 1, ids: [47, 48] },
        { id: 25, cantidad: 0, ids: [49] },
        { id: 26, cantidad: 0, ids: [50] },
      ];

      const respuestasClonadas = this.clonarYReasignarIds(respuestas, idsParaClonar);

      return respuestasClonadas
    },
    async saveData() {

      if (!this.isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        );
        return
      }
      this.$isLoading(true);
      // verificar si el formulario ya fue enviado
      const consulta = await this.useRequestSisi({
        method: "GET",
        path: "encuestas/obtener",
        params: {
          idVigilado: this.credential.usuario,
          idEncuesta: "1",
          idReporte: this.$route.query.reporte,
          token: this.TOKEN_SISI,
        }
      });

      if (consulta.ok && consulta.data.estadoActual == "Finalizado") {
        Swal.fire("Notificación!", "El reporte ya fue enviado a ST.", "warning");
        this.$isLoading(false);
        return
      }

      try {
        this.item.FechaEvaluacion = this.$moment
          .tz(this.item.FechaEvaluacion, "America/Costa_Rica")
          .format("YYYY-MM-DDTHH:mm:ssZ");

        let isSaved, data;

        if (this.item.id) {
          // alert('es una actualización')
          data = await this.generarPasosEvaluacion(this.item.id);

          // console.log("items ----> ", data);
          isSaved = await this.$store.dispatch("hl_post", {
            path: "PesvPasosEvaluacion/CreatePasos/",
            data: data,
          });

          if (this.item.Estado == "Finalizado") {
            isSaved = await this.$store.dispatch("hl_post", {
              path: "PesvPasosEvaluacion/Update/",
              data: this.item,
            });
          }
        }

        if (!isSaved) {
          Swal.fire("Error!", "Ha ocurrido un error. Intenta de nuevo.", "error");
          this.$isLoading(false);
          return
        }

        const sendDataRes = this.processRespuestas(data);

        const body = {
          idVigilado: this.credential.usuario,
          token: this.TOKEN_SISI,
          respuestas: sendDataRes,
        }

        // Guardar respuestas en Sisi
        const saveRespuestas = await this.useRequestSisi({
          method: "POST",
          path: "respuestas/guardar/" + this.$route.query.reporte,
          body
        });

        if (!saveRespuestas.ok) {
          Swal.fire("Notificación!", saveRespuestas.data.mensaje, "warning");
          this.$isLoading(false);
          return
        }

        Swal.fire(
          "Listo!",
          `Formulario guardado correctamente.`,
          "success"
        );

        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        this.item = {};
        Swal.fire("Error!", "Ha ocurrido un error. Intenta de nuevo.", "error");

        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("getw", { path: "pesv/" });
        // console.log("res", res);
        if (res.length > 0) {
          // console.log(this.items);
          return res;
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    },
  },
};
</script>
<style></style>
