<template>
  <div class="p-3">
    <p class="h4" v-if="!filteredModel.length">
      Estos datos son trimestrales, solo es necesario llenarlos en los meses:
      marzo, junio, septiembre, diciembre
    </p>
    <div
      v-for="(item, index) in filteredModel"
      :key="index"
      :class="`row p-3 ${
        index !== filteredModel.length - 1 && 'border-bottom'
      }`"
      style="gap: 0.5rem"
    >
      <div class="col-12 col-md-1">
        <b>{{ item.variableIndicador }}</b>
      </div>
      <div class="col-12 col-md-4" style="line-height: 1.5">
        {{ item.indicador }}
      </div>
      <div class="col-12 col-md d-flex align-items-center" style="gap: 1rem">
        <b class="text-nowrap"
          ><span class="text-danger">* </span>{{ selectedPeriodo }}</b
        >

        <b-input-group v-if="item.tipoDato === 'moneda'">
          <template #prepend>
            <b-input-group-text>
              <strong class="text-light">$</strong>
            </b-input-group-text>
          </template>
          <b-form-input type="number" v-model="item.value"></b-form-input>
        </b-input-group>

        <b-form-input v-else type="number" v-model="item.value"></b-form-input>
      </div>
      <div class="col">
        <span>
          Tipo de dato: <b>{{ item.tipoDato }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedPeriodo: String,
    trimestralSelected: Boolean,
    currencyModel: String,
  },
  computed: {
    modelStore() {
      return this.$store.state.formsPesvFase2[this.currencyModel][
        "indicadores"
      ];
    },
    filteredModel() {
      if (this.trimestralSelected) {
        return this.modelStore;
      } else {
        return this.modelStore.filter((item) => !item.trimestral);
      }
    },
  },
};
</script>

<style scoped></style>
