<template>
  <card class="bg-dark">
    <div class="text-center p-4">
      <h3>LISTA DE ARCHIVOS</h3>
      <div>
        <b-tabs content-class="mt-3" pills v-model="tabIndex">
          <b-tab
            :title="'Fase ' + n"
            v-for="(n, index) in 4"
            :key="n"
            :active="index === 0"
            :title-link-class="linkClass(index)"
          >
            <vs-table>
              <template #thead>
                <vs-tr>
                  <vs-th> Nombre del archivo</vs-th>
                  <vs-th> Paso </vs-th>
                  <vs-th> Fecha</vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr
                  v-for="file of filteredFilesByPhase(n)"
                  :key="file.id"
                  style="text-align: left"
                  @click="gotoFile(file)"
                  class="pointer"
                >
                  <vs-td class="d-flex align-items-center" style="gap: 0.5rem">
                    <div class="icon-container">
                      <i
                        :class="generateIconsFiles(file.nombreArchivo)"
                        style="font-size: 1rem"
                      ></i>
                    </div>
                    <span>
                      {{ file.nombreArchivo }}
                    </span>
                  </vs-td>
                  <vs-td>
                    <span> {{ file.pasoId }} </span>
                  </vs-td>
                  <vs-td>
                    <span>
                      {{
                        $moment
                          .tz(file.fechaArchivo, "America/Costa_Rica")
                          .format("YYYY-MM-DD HH:mm")
                      }}
                    </span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      tabIndex: 0,
      listaArchivos: [],
    };
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["text-light"];
      }
    },
    gotoFile(it) {
      // console.log(it);
      window.open(
        it.urlArchivo,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500"
      );
    },
    async getListaArchivos() {
      if (this.item.id == undefined || this.item.id == null) {
        this.listaArchivos = [];
        return;
      }
      this.$isLoading(true);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PesvPasosEvaluacion/GetPesvListaArchivos/" + this.item.id,
        });
        console.log("res", res);
        this.$isLoading(false);
        if (res.length > 0) {
          this.listaArchivos = res;
        } else {
          this.listaArchivos = [];
        }
        console.log(this.listaArchivos);
        //return res
      } catch (error) {
        this.$isLoading(false);
      }
    },
    generateIconsFiles(fileName) {
      // Lista de posibles extensiones de archivos y sus íconos correspondientes
      const optionsIconsFiles = [
        { exts: ["pdf"], iconName: "pdf" },
        { exts: ["png", "jpg", "jpeg", "gif"], iconName: "image" },
        { exts: ["doc", "docx"], iconName: "word" },
        { exts: ["xls", "xlsx"], iconName: "excel" },
        { exts: ["ppt", "pptx"], iconName: "powerpoint" },
        { exts: ["txt"], iconName: "lines" },
        { exts: ["zip", "rar", "7z"], iconName: "zipper" },
      ];

      // Encuentra el ícono correspondiente basado en la extensión del archivo
      const match = optionsIconsFiles.find((option) =>
        option.exts.some((ext) => fileName.toLowerCase().endsWith("." + ext))
      );

      // Devuelve el ícono encontrado o el ícono por defecto si no hay coincidencias
      return match ? `fas fa-file-${match.iconName}` : "fas fa-file";
    },
    filteredFilesByPhase(phase) {
      // Método para filtrar la lista de archivos por fase
      return this.listaArchivos.filter((file) => file.faseId === phase);
    },
  },
  watch: {
    item: {
      immediate: true, // Llama al watcher inmediatamente después de la creación del componente
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        // Llama a getListaArchivos cuando la propiedad item cambia
        this.getListaArchivos();
      },
      deep: true, // Observa los cambios en las propiedades anidadas de item
    },
  },
};
</script>

<style scoped>
.icon-container {
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #12d1df;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>
