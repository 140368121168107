<template>
  <div class="p-4">
    <hr />
    <div class="d-flex justify-content-center py-2" style="gap: 1rem">
      <!-- list steps forms -->
      <button
        v-for="(item, index) of formsSteps"
        :key="item.id"
        v-b-tooltip.hover
        :title="item.title"
        class="btn-step btn btn-circle bg-dark"
        :class="activeStep == index ? 'active' : ''"
        @click="updateSteps(index)"
      >
        <span class="h3">{{ item.id }}</span>
      </button>
      <!-- list files -->
      <button
        v-b-tooltip.hover
        title="Archivos"
        class="btn-step btn btn-circle bg-dark"
        :class="activeStep == 4 ? 'active' : ''"
        @click="updateSteps(4)"
      >
        <span class="h3">E</span>
      </button>
      <!-- save changes-->
      <vs-button
        v-b-tooltip.hover
        title="Guardar Datos"
        success
        icon
        @click="saveData"
        class="save-changes-button"
        :class="{ show: shouldShowScrollButton }"
      >
        <i class="fas fa-circle-check"></i>
      </vs-button>
      <!-- btn scroll-top -->
      <div
        v-b-tooltip.hover
        title="Ir arriba"
        @click="scrollToTop()"
        class="scroll-top-button m-1 bg-dark"
        :class="{ show: shouldShowScrollButton }"
      >
        <i class="fas fa-circle-arrow-up" style="font-size: 1.2rem"></i>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    formsSteps: Array,
    activeStep: Number,
  },
  data() {
    return {
      scrollThreshold: 700,
      showScrollButton: false,
    };
  },
  computed: {
    shouldShowScrollButton() {
      return this.showScrollButton;
    },
  },
  methods: {
    saveData() {
      this.$emit("guardar");
    },
    updateSteps(newStep) {
      this.scrollToTop();
      this.$emit("update-steps", newStep);
    },
    scrollToTop(height = 0) {
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showScrollButton = window.scrollY > this.scrollThreshold;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.btn-step:hover {
  border: 2px solid #fff;
}
.btn-step.active {
  border: 2px solid #fff;
}
</style>
